App.handleChart = function (d) {

    d3.formatDefaultLocale({
        "decimal": ",",
        "thousands": ".",
        "grouping": [3],
        "currency": ["€\u00a0", ""]
    });

    var prevYear;

    function filterFunction(dateYear){
        dateYear = new Date(dateYear);
        var n = dateYear.getFullYear();
        var currYear = n;

        if(prevYear !== currYear){
            prevYear = currYear;
            return dateYear;
        }
    }

    var svg = d3.select(".js--price-chart"),
        margin = {top: 30, right: 50, bottom: 50, left: 60},
        width = (window.innerWidth - margin.left )- margin.right,
        height = (500 - margin.top) - margin.bottom;

    var formatDate = d3.timeFormat("%b - %y");
    var bisectDate = d3.bisector(function(d) { return d.date; }).left;
    var formatNumber = d3.format("$,");

    var mindate = new Date(d3.min(chartData, function (d) {
            return d.date;
        })),
        maxdate =  new Date(d3.max(chartData, function (d) {
            return d.date;
        }));

    var minprice = d3.min(chartData, function (d) {
            return d.price;
        }),
        maxprice = d3.max(chartData, function (d) {
            return d.price;
        });

    var x = d3.scaleBand()
        //.domain([d3.timeMonth.offset(mindate, -3), d3.timeMonth.offset(maxdate, 3)]);
        .domain(chartData.map(function(d) { return d.date; }))
        .rangeRound([(2200) * -1, 2200]);

    var xAxis = d3.axisBottom(x)
        .tickValues(x.domain())
        .tickFormat(d3.timeFormat("%b"))
        .tickSize(0)
        .tickPadding(6);

    var xAxisSec = d3.axisBottom(x)
        .tickValues(x.domain().filter(filterFunction))
        .tickFormat(d3.timeFormat("%Y"))
        .tickSize(30)
        .tickPadding(12);

    var y = d3.scaleLinear()
        .domain([maxprice, 0])
        .rangeRound([0, height - margin.top]);

    var yAxis = d3.axisLeft(y)
        .tickSize(width, margin.right)
        .tickFormat(function(d) {
            return formatNumber(d);
        })
        .tickPadding(12);

    var dataContainer = svg.append("g")
        .attr("class", "container");

    var bar = dataContainer.selectAll("g")
        .data(chartData, function (d) {
            return d;
        })
        .enter().append("g");


    bar.append("rect")
        .attr("class", "bar")
        .attr("height", function (d) {
            return (height - margin.top) - y(d.price);
        })
        .attr("y", function (d) {
            return y(d.price) + margin.top;
        })
        .attr("x", function(d) { return x(d.date); })
        .style("stroke-width", 3)
        .attr("width", x.bandwidth());


    bar.append("text")
        .text(function (d) {
            return "€" + d.price;
        })
        .attr("class", "bar-price")
        .attr("x", function (d) {
            return x(d.date) + 5;
        })
        .attr("y", function (d) {
            return (y(d.price) + margin.top) - 5;
        });

    var gX = svg.append("g")
        .attr("class", "axis axis--x")
        .attr("transform", "translate(0," + height + ")")
        .call(xAxis);

    var gXsec = svg.append("g")
        .attr("class", "axis axis--x-sec")
        .attr("transform", "translate(0," + height + ")")
        .call(xAxisSec);


    gXsec.selectAll("text")
        .attr("y", 20)
        .attr("x", -10)
        .style("text-anchor", "start")
        .style("font-weight", "700")
        .style("font-size", "14px");

    gXsec.selectAll("line")
        .attr("x1", -12)
        .attr("x2", -12)
        .style("shape-rendering", "crispEdges")
        .style("stroke-width", 3);

    svg.append("rect")
        .attr("height", height + margin.top + margin.bottom)
        .attr("width", margin.right)
        .attr("transform", "translate(" + (width + margin.left)  +  ",0)")
        .attr("class", "bar-hide");


    svg.append("rect")
        .attr("height", height + margin.top + margin.bottom)
        .attr("width", margin.left)
        .attr("transform", "translate(0," + margin.top + ")")
        .attr("class", "bar-hide");


    var gY = svg.append("g")
        .attr("class", "axis axis--y")
        .attr("transform", "translate(" + (width + margin.left) +  "," + margin.top + ")")
        .call(yAxis);

    svg.call(d3.zoom()
        .scaleExtent([1, 1])
        .translateExtent([[(2400) * -1, 0], [2400, height]])
        .on('zoom', zoomed)
    );

    function zoomed() {
        var transform = d3.zoomTransform(this);
        dataContainer.attr("transform", "translate(" + (transform.x - 60) + ") ");
        gX.attr("transform", "translate(" + (transform.x - 60) + ", " + height + " )");
        gXsec.attr("transform", "translate(" + (transform.x - 60) + ", " + height + " )");
    }
};